import { Box, Dialog, DialogContent, Link } from "@mui/material";
import DraggableHeader from "../components/DraggableHeader";
import AdminDataGrid from "../components/AdminDataGrid";
import { useEffect, useState } from "react";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import PodoDialogTitle from "../components/PodoDialogTitle";

const AdminUserSearch = (props) => {
  const { showMessage } = useSnackbar();
  const { api } = useUtils();

  const [ rows, setRows ] = useState([]);

  const handleRead = () => {
    api.post('/api/admin/users', {})
      .then(response => {
        setRows(response.data?.data);
      })
      .catch(e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error')
      });
  };

  const handleSelect = (user) => {
    props.handleClose(user);
  };

  useEffect(() => {
    if (props.open) {
      handleRead();
    }
  }, [props.open]);

  const columns = [
    { field: 'userId', headerName: '사용자ID', type: 'string', align: 'center', flex: 2, renderCell: data => (<Link component="button" onClick={() => handleSelect(data.row)}>{data.row.userId}</Link>)},
    { field: 'userNm',  headerName: '사용자명', type: 'string', align: 'left', flex: 3},
    { field: 'cmpnyNm',  headerName: '소속기관명', type: 'string', align: 'left', flex: 3},
  ]

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <PodoDialogTitle text="사용자 검색" onClose={() => props.handleClose()}/>
      <DialogContent sx={{mt: 2, pb: 0}}>
        <Box display='flex' justifyContent='flex-end'>
        </Box>

        <Box sx={{minWidth: 350}}>
          <AdminDataGrid
            rows={rows}
            columns={columns}
            getRowId={row => row.userId}
            density="compact"
            disableColumnMenu
            autoHeight
            hideFooter
          />
      </Box>
      </DialogContent>
    </Dialog>
  )
};

export default AdminUserSearch;