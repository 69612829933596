import { styled, TextField } from "@mui/material";

const CompactTextField = styled(({slotProps, ...props}) => (
  <TextField
  {...props}
  slotProps={{
    inputLabel: { shrink: true },
    ...slotProps, // 기존 slotProps 병합
  }}
  />
))

(({ theme }) => ({
  '& input.MuiInputBase-input': {
    fontSize: '14px',
    paddingTop: '6px',
    paddingBottom: '6px'
  },
  '& label.MuiInputLabel-root': {
    fontSize: '14px',
    transform: 'translate(14px, 7px) scale(1)'
  },
  '& label.MuiInputLabel-root[data-shrink=true]': {
    transform: 'translate(14px, -7px) scale(0.75)'
  },
  '& p.MuiFormHelperText-root': {
    lineHeight: 1,
    marginTop: '2px',
    marginBottom: '-2px',
  },
  '& div.MuiInputBase-root': {
    fontSize: '14px'
  },
  '& div.MuiSelect-select': {
    paddingTop: '6px',
    paddingBottom: '6px'
  },
  '& p.MuiFormHelperText-root': {
    position: 'absolute',
    right: '0%',
    transform: 'translateY(15%)',
    pointerEvents: 'none'
  }
}));

export default CompactTextField;