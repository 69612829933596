import { Card } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useUtils } from "../../contexts/UtilContext";
import DeviceCardHeader from "./DeviceCardHeader";
import DeviceCardContent from "./DeviceCardContent";
import PropLabel from "./PropLabel";
import PropValue from "./PropValue";

const Wthr = (props) => {
  const { formatNumber, formatWeather } = useUtils();

  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  return (
    <Grid item size={5}>
      <Card>
        <DeviceCardHeader node={node} dvc={dvc} setup/>
        <DeviceCardContent>
          <Grid container alignItems='start' justifyContent="space-evenly">
            <Grid item size={2} textAlign='center'>
              <PropLabel>좌표(X/Y)</PropLabel>
              <PropValue>{ data.nx || '-'} / { data.ny || '-'}</PropValue>
            </Grid>
            <Grid item size={2} textAlign='center'>
              <PropLabel>온도</PropLabel>
              <PropValue unit="℃">{formatNumber(data.t1h, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            <Grid item size={2} textAlign='center'>
              <PropLabel>습도</PropLabel>
              <PropValue unit="%RH">{formatNumber(data.reh, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            <Grid item size={2.5} textAlign='center'>
              <PropLabel>날씨</PropLabel>
              <PropValue>{formatWeather(data.sky, data.pty) || '-'}</PropValue>
            </Grid>
            <Grid item size={2} textAlign='center'>
              <PropLabel>풍속</PropLabel>
              <PropValue unit="m/s">{formatNumber(data.wsd) || '-'}</PropValue>
            </Grid>
          </Grid>
        </DeviceCardContent>
      </Card>
    </Grid>
  )

}

export default Wthr;