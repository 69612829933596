import { useEffect } from "react";
import { Header } from "../components/Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";


const Admin = () => {

  const navigator = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if (location.pathname.endsWith('/admin')) {
      navigator('/admin/nodes');
    }
  }, [location.pathname, navigator]);
  
  return (
    <>
      <Header />
      <main style={{ marginTop: '48px', padding: '20px' }}>
        <Outlet/>
      </main>
    </>
  )
};

export default Admin;