import { Card } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useUtils } from "../../contexts/UtilContext";
import DeviceCardHeader from "./DeviceCardHeader";
import DeviceCardContent from "./DeviceCardContent";
import PropLabel from "./PropLabel";
import PropValue from "./PropValue";
import Auto from "./Auto";
import Led from "../Led";
import { useCode } from "../../contexts/CodeContext";
import { useAuth } from "../../contexts/AuthContext";

const Ardm = (props) => {
  const { user } = useAuth();
  const { codes } = useCode();
  const { timeString, formatNumber } = useUtils();

  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  const isAdmin = Array.isArray(user?.roles) && user.roles.includes('ROLE_ADMIN');

  return (
    <Grid item size={8}>
      <Card>
        <DeviceCardHeader node={node} dvc={dvc} on={!!data.oprt} setup />
        <DeviceCardContent>
          <Grid container alignItems='start' justifyContent="space-evenly">
            <Grid item size={1.5} textAlign='center'>
              <PropLabel>제어모드</PropLabel>
              <Auto value={!!data.auto} size="small" />
            </Grid>
            <Grid item size={1.5} textAlign='center'>
              <PropLabel>운전</PropLabel>
              <Led value={!!data.oprt} size="small"  />
            </Grid>
            <Grid item size={1.5} textAlign='center'>
              <PropLabel>운전모드</PropLabel>
              <PropValue>{codes.ArdmOprtMode?.find(item => item.code === data.oprtMode)?.label || '-'}</PropValue>
            </Grid>
            <Grid item size={1.5} textAlign='center'>
              <PropLabel>외기온도</PropLabel>
              <PropValue unit="℃">{formatNumber(data.otdrTemp, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            <Grid item size={1.5} textAlign='center'>
              <PropLabel>내부온도</PropLabel>
              <PropValue unit="℃">{formatNumber(data.raTemp, {minimumFractionDigits: 1, maximumFractionDigits: 1}) || '-'}</PropValue>
            </Grid>
            <Grid item size={2} textAlign='center'>
              <PropLabel>운영시간</PropLabel>
              <PropValue>{timeString(data.oprtStHm, data.oprtEdHm)}</PropValue>
            </Grid>
            { isAdmin &&
            <Grid item size={1} textAlign='center'>
              <PropLabel>경보</PropLabel>
              <PropValue color={data.err ? "error" : "inherit"}>{data.err || '-'}</PropValue>
            </Grid>
            }
          </Grid>
        </DeviceCardContent>
      </Card>
    </Grid>
  )

}

export default Ardm;