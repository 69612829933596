import { Dialog } from "@mui/material"
import Grid from "@mui/material/Grid2";
import DraggableHeader from "../components/DraggableHeader"
import PodoDialogTitle from "../components/PodoDialogTitle";
import PodoDialogContent from "../components/PodoDialogContent";
import PodoDialogActions from "../components/PodoDialogActions";

import Arss from "../components/device/Arss";
import Arkr from "../components/device/Arkr";
import Wthr from "../components/device/Wthr";
import Ardm from "../components/device/Ardm";
import Ldlt from "../components/device/Ldlt";
import Chgr from "../components/device/Chgr";
import Htbc from "../components/device/Htbc";
import Uvld from "../components/device/Uvld";
import Fdld from "../components/device/Fdld";
import Awss from "../components/device/Awss";
import { useAuth } from "../contexts/AuthContext";

const Airdome = (props) => {
  const { user } = useAuth();

  const node = props.node;
  const dvcs = props.dvcs;

  const isAdmin = Array.isArray(user?.roles) && user.roles.includes('ROLE_ADMIN');

  const displayDevices = ( list ) => list.sort((a, b) => a.ordNo - b.ordNo).map((dvc, index) => {
    const data = dvc.data;

    switch (dvc.dvcTypeCd) {
      case "ARSS": return (<Arss key={index} node={node} dvc={dvc} data={data} mini />)
      case "ARKR": return (<Arkr key={index} node={node} dvc={dvc} data={data} />)
      case "WTHR": return (isAdmin && <Wthr key={index} node={node} dvc={dvc} data={data} />)
      case "AWSS": return (<Awss key={index} node={node} dvc={dvc} data={data} />)
      case "ARDM": return (<Ardm key={index} node={node} dvc={dvc} data={data} />)
      case "LDLT": return (<Ldlt key={index} node={node} dvc={dvc} data={data} />)
      case "CHGR": return (<Chgr key={index} node={node} dvc={dvc} data={data} />)
      case "HTBC": return (<Htbc key={index} node={node} dvc={dvc} data={data} />)
      case "UVLD": return (<Uvld key={index} node={node} dvc={dvc} data={data} />)
      case "FDLD": return (<Fdld key={index} node={node} dvc={dvc} data={data} />)
      
      // case "ESVS": return (<Esvs key={index} node={node} dvc={dvc} data={data} />)
      
    }
  });

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader} aria-hidden={!props.open} maxWidth="lg">
      <PodoDialogTitle on={!!node.data?.connected} text={`[${node.nodeId}] ${node.nodeNm}`} onClose={() => props.handleClose()} />
      <PodoDialogContent>
        <Grid container spacing={2} sx={{minWidth: 1200, mb: .1}}>
          {displayDevices(dvcs)}
        </Grid>
      </PodoDialogContent>
      <PodoDialogActions/>
    </Dialog>
  );
};

export default Airdome;