import { Refresh, ShowChart } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { blue, cyan, grey, indigo, lime, purple, red, teal } from "@mui/material/colors";
import Led from "./Led";
import { useDialogs } from "../contexts/DialogContext";
import { useInterval } from "../contexts/useInterval";
import PropLabel from "./device/PropLabel";
import PropValue from "./device/PropValue";
import { useCode } from "../contexts/CodeContext";

const NodeHealthpole = (props) => {
  const node = props.node;
  const { codes } = useCode();
  const { openDialog, getDialog } = useDialogs();
  const { api, formatNumber, formatWeather } = useUtils();
  const { showMessage } = useSnackbar();

  const [dvcList, setDvcList] = useState([]);
  const [radr1, setRadr1] = useState({});
  const [radr2, setRadr2] = useState({});

  const handleRead = () => {
    api.post(`/api/devices`, {
      nodeId: node.nodeId
    })
    .then(response => {
      const dvcs = response.data?.data;
      if (!!dvcs) {
        setDvcList(dvcs.sort((a, b) => a.ordNo - b.ordNo));
      }
    }).catch((e) => {
      showMessage(`[${e.response?.status}] ${e.message}`, 'error')
    });
  };
  
  useEffect(() => {
    if (!!node.nodeId) {
      handleRead();
    };

  }, []);

  useEffect(() => {
    if (dvcList) {
      setRadr1(dvcList.find(dvc => dvc.dvcId.endsWith('RADR1')) || {});
      setRadr2(dvcList.find(dvc => dvc.dvcId.endsWith('RADR2')) || {});
    }
  }, [dvcList])

  const handleRefresh = (dvc) => {
    api.get(`/api/nodes/${node.nodeId}/refresh`, {})
    .then(response => {
    }).catch((e) => {
      showMessage(`[${e.response?.status}] ${e.message}`, 'error');
    });

    dvcList.forEach(dvc => {
      api.get(`/api/devices/${dvc.dvcId}/refresh`, {})
      .then(response => {
      }).catch((e) => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error');
      });
    });
  };

  const handleNode = (node) => {
    openDialog('healthpole', {
      node: node,
      dvcs: dvcList
    })
  };

  const handleAttachFiles = (node) => {
    openDialog('attachFiles', {
      title: '원격디스플레이장치에 표시되는 사진을 관리할 수 있습니다.',
      node: node
    })
  }

  const displayNodeState = (node) => {
    return !!node.data?.connected ? "ONLINE" : "OFFLINE";
  }

  useInterval(() => {
    if(node) {
      handleRead();
    }
  }, 1 * 10000);

  return (
    <Grid item size={12}>
      <Card>
        <CardHeader
          title={node.nodeId}
          titleTypographyProps={{variant: 'subtitle3', display: 'flex', mx: 1}}
          subheader={node.nodeNm}
          subheaderTypographyProps={{variant: 'subtitle1', fontWeight: 500, display: 'flex', color: 'inherit'}}
          action={<>
              <Button variant="text" size="small" startIcon={<Refresh size="small" sx={{mr: -1}}/>} onClick={() => handleRefresh(node)} sx={{color: !!node.data?.connected ? '#fff' : 'grey.700'}}>새로고침</Button>
              <Button variant="text" size="small" startIcon={<ShowChart size="small" sx={{mr: -1}}/>} onClick={() => handleNode(node)} sx={{color: !!node.data?.connected ? '#fff' : 'grey.700', minWidth: 'unset'}}>기록</Button>
          </>}
          sx={{px:1, py: .5, color: !!node.data?.connected ? '#fff' : 'grey.700', bgcolor: !!node.data?.connected ? 'primary.main' : 'grey.300', '& .MuiCardHeader-action': {alignSelf: 'center', margin: 0}, '& .MuiCardHeader-content': {display: 'flex', alignItems: 'center'}}}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item size={2} container direction="column" justifyContent="space-evenly" spacing={1}>
              <Grid item container alignItems="center">
                <PropLabel>상태</PropLabel>
                <PropValue sx={{ml: 2}}>{displayNodeState(node)}</PropValue>
              </Grid>
              <Grid item container alignItems="center">
                <PropLabel>배터리</PropLabel>
                <PropValue unit="%" sx={{ml: 2}}>{formatNumber(node.data?.batt) || '-'}</PropValue>
              </Grid>
            </Grid>
            <Grid item size={5} container spacing={2}>
              <Grid item size={12}>
                <Paper elevation={0} sx={{p: 1, bgcolor: indigo[50]}}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item size={1} sx={{textAlign: 'center'}}>CH1</Grid>
                    <Grid item size={2} sx={{textAlign: 'center'}}>
                      <PropLabel>사용중</PropLabel>
                      <Led value={!!radr1.data?.run} blink />
                    </Grid>
                    <Grid item size={3.5} sx={{textAlign: 'center'}}>
                      <PropLabel>시작시간</PropLabel>
                      <PropValue variant="h7">{radr1.data?.stDtm?.substring(5) || '-'}</PropValue>
                    </Grid>
                    <Grid item size={3.5} sx={{textAlign: 'center'}}>
                      <PropLabel>종료시간</PropLabel>
                      <PropValue variant="h7">{radr1.data?.edDtm?.substring(5) || '-'}</PropValue>
                    </Grid>
                    <Grid item size={2} sx={{textAlign: 'center'}}>
                      <PropLabel>횟수</PropLabel>
                      <PropValue variant="h7">{radr1.data?.cnt || '-'}</PropValue>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item size={5} container spacing={2}>
              <Grid item size={12}>
                <Paper elevation={0} sx={{p: 1, bgcolor: indigo[50]}}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item size={1} sx={{textAlign: 'center'}}>CH2</Grid>
                    <Grid item size={2} sx={{textAlign: 'center'}}>
                      <PropLabel>사용중</PropLabel>
                      <Led value={!!radr2.data?.run} blink />
                    </Grid>
                    <Grid item size={3.5} sx={{textAlign: 'center'}}>
                      <PropLabel>시작시간</PropLabel>
                      <PropValue variant="h7">{radr2.data?.stDtm?.substring(5) || '-'}</PropValue>
                    </Grid>
                    <Grid item size={3.5} sx={{textAlign: 'center'}}>
                      <PropLabel>종료시간</PropLabel>
                      <PropValue variant="h7">{radr2.data?.edDtm?.substring(5) || '-'}</PropValue>
                    </Grid>
                    <Grid item size={2} sx={{textAlign: 'center'}}>
                      <PropLabel>횟수</PropLabel>
                      <PropValue variant="h7">{radr2.data?.cnt || '-'}</PropValue>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
};

export default NodeHealthpole;