import { Card } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Auto from "./Auto";
import { useUtils } from "../../contexts/UtilContext";
import Led from "../Led";
import DeviceCardHeader from "./DeviceCardHeader";
import DeviceCardContent from "./DeviceCardContent";
import PropLabel from "./PropLabel";
import PropValue from "./PropValue";

const Ldlt = (props) => {
  const { timeString } = useUtils();

  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  return (
    <Grid item size={4}>
      <Card>
        <DeviceCardHeader node={node} dvc={dvc} on={!!data?.pwr} setup />
        <DeviceCardContent>
          <Grid container alignItems='start' justifyContent="space-evenly">
            <Grid item size={2} textAlign='center'>
              <PropLabel>제어모드</PropLabel>
              <Auto value={!!data.auto} size="small" />
            </Grid>
            <Grid item size={2} textAlign='center'>
              <PropLabel>전원</PropLabel>
              <Led value={!!data.pwr} size="small" />
            </Grid>
            <Grid item size={4} textAlign='center'>
              <PropLabel>운영시간</PropLabel>
              <PropValue>{timeString(data.oprtStHm, data.oprtEdHm)}</PropValue>
            </Grid>
            <Grid item size={4} textAlign='center'>
              <PropLabel>운영시간2</PropLabel>
              <PropValue>{timeString(data.oprtStHm2, data.oprtEdHm2)}</PropValue>
            </Grid>
          </Grid>
        </DeviceCardContent>
      </Card>
    </Grid>
  )

}

export default Ldlt;