import CompactFormControlLabel from "../components/CompactFormControlLabel";
import PodoDialogTitle from "../components/PodoDialogTitle";
import PodoDialogContent from "../components/PodoDialogContent";
import PodoDialogActions from "../components/PodoDialogActions";

import { isNotEmpty } from "../utils/validations";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useCode } from "../contexts/CodeContext";
import { useForm } from "../hooks/useForm";
import { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, MenuItem, Switch } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CompactTextField from "../components/CompactTextField";
import { useUtils } from "../contexts/UtilContext";
import DraggableHeader from "../components/DraggableHeader";

const AdminDevice = (props) => {
  const { api, confirmMessage} = useUtils();
  const { showMessage } = useSnackbar();
  const { codes } = useCode();
  
  const mode = isNotEmpty(props.dvcId) ? 'U' : 'C';

  const initialValues = {
    nodeId: props.nodeId,
    nodeNm: props.nodeNm,
    dvcTypeCd: '',
    dvcSubTypeCd: '',
    dvcId: '',
    dvcNm: '',
    ordNo: '',
    useYn: true,
  };

  const { values, setValues, errors, touched, handleChange, resetForm, isValid } = useForm(
    initialValues,
    [
      ({dvcTypeCd}) => isNotEmpty(dvcTypeCd) || {dvcTypeCd: '디바이스타입을 선택해주세요.'},
      ({dvcId}) => isNotEmpty(dvcId) || {dvcId: '디바이스ID를 입력해주세요.'},
      ({dvcNm}) => isNotEmpty(dvcNm) || {dvcNm: '디바이스명을 입력해주세요.'},
    ]
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.open && mode === 'U') {
      api.get(`/api/admin/nodes/${props.nodeId}/devices/${props.dvcId}`)
        .then((response) => {
          setValues(prev => ({...prev, ...response.data?.data}));
        })
        .catch((e) => {
          showMessage(`[${e.response?.status}] ${e.message}`, 'error')
        });

    } else {
      resetForm(initialValues);
    }
  }, [props.open]);

  /* 저장 */
  const handleSave = () => {
    let data = Object.assign({}, values);

    //수정
    if (mode === 'U') {
      confirmMessage('변경하시겠습니까?', () => {
        setIsLoading(true);
        api.post(`/api/admin/nodes/${props.nodeId}/devices/${props.dvcId}/update`, data)
          .then(response => {
            showMessage('변경되었습니다.');
            props.handleClose(true);
            setIsLoading(false);
          }).catch((e) => {
            if (e.response?.status === 401) props.handleClose();
            showMessage(`[${e.response?.status}] ${e.message}`, 'error')
            setIsLoading(false);
          });
      });
    
    } else {
      confirmMessage('디바이스를 등록하시겠습니까?', () => {
        setIsLoading(true);
        data.dvcId = `${props.nodeId}-${data.dvcId}`;  //노드ID를 자동으로 붙임
        api.post(`/api/admin/nodes/${props.nodeId}/devices/create`, data)
          .then(response => {
            showMessage('등록되었습니다.');
            props.handleClose(true);
            setIsLoading(false);
          })
          .catch((e) => {
            if (e.response?.status === 401) props.handleClose();
            showMessage(`[${e.response?.status}] ${e.message}`, 'error');
            setIsLoading(false);
          });
      });
    }
  };

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <PodoDialogTitle text={props.title} onClose={() => props.handleClose()}/>
      <PodoDialogContent>
        <Grid container columnSpacing={2}>
          <Grid item size={{xs: 12, sm: 4}}>
            <CompactTextField name='dvcTypeCd' label="디바이스타입"
              select
              value={values.dvcTypeCd}
              onChange={handleChange}
              required
              size='small'
              sx={{}}
              error={touched.dvcTypeCd && !!errors.dvcTypeCd}
              helperText={(touched.dvcTypeCd && errors.dvcTypeCd) || ' '}
              slotProps={{
                formHelperText: {sx: {minHeight: '1.5em'}},
                htmlInput: { maxLength: 30 }
              }}
              margin="dense"
              fullWidth
              autoComplete="off"
              disabled={mode === 'U'} >
              { codes.DeviceType?.map((code, index) => <MenuItem key={index} value={code.code} disabled={!code.active} dense>{code.label}({code.code})</MenuItem>)}
            </CompactTextField>
          </Grid>
          <Grid item size={{xs: 12, sm: 4}}>
            <CompactTextField name='dvcSubTypeCd' label="디바이스서브타입"
              select
              value={values.dvcSubTypeCd}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.dvcSubTypeCd && !!errors.dvcSubTypeCd}
              helperText={(touched.dvcSubTypeCd && errors.dvcSubTypeCd) || ' '}
              slotProps={{
                formHelperText: {sx: {minHeight: '1.5em'}},
                htmlInput: { maxLength: 30 }
              }}
              margin="dense"
              fullWidth
              autoComplete="off" >
              { codes.DeviceSubType?.map((code, index) => <MenuItem key={index} value={code.code} disabled={!code.active} dense>{code.label}({code.code})</MenuItem>)}
            </CompactTextField>
          </Grid>
          <Grid item size={{xs: 12, sm: 4}}>
            <CompactTextField name='dvcId' label="디바이스ID"
              value={values.dvcId}
              onChange={handleChange}
              required
              size='small'
              sx={{}}
              error={touched.dvcId && !!errors.dvcId}
              helperText={(touched.dvcId && errors.dvcId) || ' '}
              slotProps={{
                formHelperText: {sx: {minHeight: '1.5em'}},
                htmlInput: { maxLength: 10 }
              }}
              margin="dense"
              fullWidth
              autoComplete="off"
              disabled={mode === 'U'} />
          </Grid>

          <Grid item size={{xs: 12, sm: 4}}>
            <CompactTextField name="dvcNm" label="디바이스명"
              value={values.dvcNm}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.dvcNm && !!errors.dvcNm}
              helperText={(touched.dvcNm && errors.dvcNm) || ' '}
              slotProps={{
                formHelperText: {sx: {minHeight: '1.5em'}},
                htmlInput: { maxLength: 30 }
              }}
              margin="dense"
              required
              fullWidth
              autoComplete="off" />
          </Grid>
          <Grid item size={{xs: 12, sm: 4}}>
            <CompactTextField name="ordNo" label="정렬순서" error={touched.ordNo && !!errors.ordNo} helperText={(touched.ordNo && errors.ordNo) || ' '} size="small" margin='dense' value={values.ordNo || ''} onChange={handleChange} inputProps={{inputMode: 'numeric', pattern: '[0-9]*', maxLength: 5 }}  fullWidth />
          </Grid>
          <Grid item size={{xs: 12, sm: 4}}>
            <CompactFormControlLabel
              control={<Switch name="useYn" checked={!!values.useYn} onChange={handleChange} />}
              label="사용여부" sx={{mt: 0.5}} />
          </Grid>
        </Grid>
      </PodoDialogContent>
      <PodoDialogActions>
        <Button variant="contained" disabled={!isValid || isLoading} onClick={handleSave}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : '저장'}
        </Button>
      </PodoDialogActions>
    </Dialog>
  )
};

export default AdminDevice;