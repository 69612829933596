import React, { useState } from 'react';
import { Button, Container, Box, Typography, Paper, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../hooks/useForm';
import { isNotEmpty } from '../utils/validations';
import { useSnackbar } from '../contexts/SnackbarContext';
import logoImage from '../assets/images/logo.png';
import { useAuth } from '../contexts/AuthContext';
import { useUtils } from '../contexts/UtilContext';
import CompactTextField from '../components/CompactTextField';

const Login = () => {
  const navigate = useNavigate();
  const { api } = useUtils();
  const [isLoading, setIsLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const { login } = useAuth();

  const { values, errors, touched, handleChange, isValid } = useForm(
    {
      userId: '',
      pwd: ''
    },
    [
      ({userId}) => isNotEmpty(userId) || {userId: '사용자ID를 입력해주세요.'},
      ({pwd}) => isNotEmpty(pwd) || {pwd: '비밀번호를 입력해주세요.'},
    ]
  );

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!isValid) return;

    setIsLoading(true);
    try {
      const response = await api.post('/api/auth/login', values);
      const targetUrl = await login({...response.data, "token": response.headers['authentication']});
      navigate(targetUrl || '/main'); // 로그인 성공 시 메인페이지로 이동
    } catch (error) {
      console.error('Login failed:', error);
      showMessage('로그인에 실패했습니다. 다시 시도해주세요.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isValid) {
      handleLogin(e);
    }
  }

  return (
    <Container component="main" maxWidth="xs" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Paper elevation={3} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px' }}>
        <img src={logoImage} alt="Logo" style={{ width: '100px', marginBottom: '1rem' }} />
        <Typography component="h1" variant="h5" color="logo.main" sx={{fontWeight: 600}}>
          로그인
        </Typography>
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <CompactTextField name='userId' label='사용자 ID'
              value={values.userId}
              autoComplete="userId"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              size='small'
              sx={{}}
              error={touched.userId && !!errors.userId}
              helperText={(touched.userId && errors.userId) || ' '}
              slotProps={{
                inputLabel: {
                  shrink: true
                }
                // formHelperText: {sx: {minHeight: '1.5em'}},
                // htmlInput: { maxLength: 20 }
              }}
              margin="normal"
              required
              fullWidth
            />
          <CompactTextField name='pwd' label='비밀번호' type="password"
              value={values.pwd}
              autoComplete="current-password"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              size='small'
              sx={{}}
              error={touched.pwd && !!errors.pwd}
              helperText={(touched.pwd && errors.pwd) || ' '}
              margin="normal"
              required
              fullWidth
            />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 2}}
            disabled={!isValid || isLoading}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : '로그인'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;