import { useEffect, useState } from "react";
import { useUtils } from "../contexts/UtilContext";
import { isNotEmpty, isEmail, isEmpty } from '../utils/validations';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import DraggableHeader from "../components/DraggableHeader";
import { useForm } from "../hooks/useForm";
import CompactTextField from "../components/CompactTextField";
import { useSnackbar } from "../contexts/SnackbarContext";
import PodoDialogTitle from "../components/PodoDialogTitle";

const Account = (props) => {
  const { api, confirmMessage } = useUtils();
  const { showMessage } = useSnackbar();
  const initialValues = {
    userId: '',
    userNm: '',
    cmpnyNm: '',
    email: '',
    telNo: ''
  };
  const { values, setValues, errors, touched, handleChange, resetForm, isValid } = useForm(
    initialValues,
    [
      ({userId}) => isNotEmpty(userId) || {userId: '사용자ID를 입력해주세요.'},
      ({userNm}) => isNotEmpty(userNm) || {userNm: '사용자명을 입력해주세요.'},
      ({email}) => isEmpty(email) || isEmail(email) || {email: '유효한 이메일 주소를 입력해주세요.'},
    ]
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.open) {
      api.get(`/api/account`, {})
        .then(response => {
          setValues({...values, ...response.data?.data});
        })
        .catch(e => {
          if (e.response?.status === 401) props.handleClose();
          showMessage(`[${e.response?.status}] ${e.message}`, 'error')
        });
    } else {
      resetForm(initialValues);
    }
  }, [props.open]);

  /* 저장 */
  const handleSave = () => {
    confirmMessage('변경하시겠습니까?', () => {
      setIsLoading(true);
      api.post(`/api/account/update`, values)
        .then(response => {
          showMessage('변경되었습니다.');
          props.handleClose();
          setIsLoading(false);
        })
        .catch((e) => {
          if (e.response?.status === 401) props.handleClose();
          showMessage(`[${e.response?.status}] ${e.message}`, 'error')
          setIsLoading(false);
        })
        ;
    });
  };

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader}>
      <PodoDialogTitle text="내 정보 변경" onClose={() => props.handleClose()} />
      <DialogContent sx={{mt: 2, pb: 0}}>
        <Grid container columnSpacing={2}>
          <Grid item size={{xs: 12, sm: 6}}>
            <CompactTextField name='userId' label='사용자ID'
              value={values.userId}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.userId && !!errors.userId}
              helperText={(touched.userId && errors.userId) || ' '}
              slotProps={{
                formHelperText: {sx: {minHeight: '1.5em'}},
                htmlInput: { maxLength: 20 }
              }}
              margin="dense"
              required
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item size={{xs: 12, sm: 6}}>
            <CompactTextField name="userNm" label="성명"
              value={values.userNm}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.userNm && !!errors.userNm}
              helperText={(touched.userNm && errors.userNm) || ' '}
              slotProps={{
                formHelperText: {sx: {minHeight: '1.5em'}},
                htmlInput: { maxLength: 30 }
              }}
              margin="dense"
              required
              fullWidth
              />
          </Grid>
          <Grid item size={{xs: 12, sm: 6}}>
            <CompactTextField name="cmpnyNm" label="소속기관명"
              value={values.cmpnyNm}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.cmpnyNm && !!errors.cmpnyNm}
              helperText={(touched.cmpnyNm && errors.cmpnyNm) || ' '}
              slotProps={{
                formHelperText: {sx: {minHeight: '1.5em'}},
                htmlInput: { maxLength: 100 }
              }}
              margin="dense"
              fullWidth
              />
          </Grid>
          <Grid size={{xs: 12, sm: 6}}>
            <CompactTextField name="email" label="이메일"
              value={values.email}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={isNotEmpty(values.email) && touched.email && !!errors.email}
              helperText={(isNotEmpty(values.email) && touched.email && errors.email) || ' '}
              slotProps={{
                formHelperText: {sx: {minHeight: '1.5em'}},
                htmlInput: { inputMode: 'email', maxLength: 100 }
              }}
              margin="dense"
              fullWidth
              />
          </Grid>
          <Grid size={{xs: 12, sm: 6}}>
            <CompactTextField name="telNo" label="전화번호"
              value={values.telNo}
              onChange={handleChange}
              size='small'
              sx={{}}
              error={touched.telNo && !!errors.telNo}
              helperText={(touched.telNo && errors.telNo) || ' '}
              slotProps={{
                formHelperText: {sx: {minHeight: '1.5em'}},
                htmlInput: { maxLength: 100 }
              }}
              margin="dense"
              fullWidth
              />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{pr: 3, pb: 2}}>
        <Button variant="contained" disabled={!isValid || isLoading} onClick={handleSave}>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : '저장'}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default Account;