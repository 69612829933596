import { Box, Dialog, DialogContent, DialogTitle, IconButton, Link } from "@mui/material";
import DraggableHeader from "../components/DraggableHeader";
import { Close, Search } from "@mui/icons-material";
import AdminDataGrid from "../components/AdminDataGrid";
import { useEffect, useState } from "react";
import { useUtils } from "../contexts/UtilContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import { grey } from "@mui/material/colors";
import CompactTextField from "../components/CompactTextField";
import { useForm } from "../hooks/useForm";
import HelpTooltip from "../components/HelpTooltip";

const AkSttnSearch = (props) => {
  const { showMessage } = useSnackbar();
  const { api, formatNumber } = useUtils();
  const node = props.node;
  const dvc = props.dvc;
  const data = dvc.data;

  const [ rows, setRows ] = useState([]);
  const { values, handleChange, resetForm } = useForm({
    sttn: data?.sttn || '',
    addr: ''
  }, []);

  const handleRead = () => {
    api.post('/api/common/akSttns', values)
      .then(response => {
        const list = response.data?.data;
        if (Array.isArray(list)) {
          list.forEach(stn => {
            stn.dist = calculateDistance(stn);
          });

          setRows(list.sort((a, b) => a.dist - b.dist));
        }
      })
      .catch(e => {
        showMessage(`[${e.response?.status}] ${e.message}`, 'error')
      });
  };

  const handleSelect = (akSttn) => {
    props.handleClose(akSttn);
  };

  const radians = (degree) => {
    return Math.PI / 180 * degree
  }

  const calculateDistance = (data) => {
    if (!isNaN(node?.instlLattd) && !isNaN(node?.instlLngtd)) {
      return 6371 * Math.acos(Math.cos(radians(90 - node?.instlLattd)) * Math.cos(radians(90 - data.dmY)) + Math.sin(radians(90 -node?.instlLattd)) * Math.sin(radians(90 - data.dmY)) * Math.cos(radians(node?.instlLngtd - data.dmX)));
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (props.open) {
      handleRead();
    } else {
      resetForm({
        sttn: '',
        addr: ''
      })
    }
  }, [props.open]);

  const columns = [
    { field: 'stationName', headerName: '측정소', type: 'string', align: 'center', flex: 3, renderCell: data => (<Link component="button" onClick={() => handleSelect(data.row)}>{data.row.stationName}</Link>)},
    { field: 'addr',  headerName: '주소', type: 'string', align: 'left', flex: 10},
    { field: 'dist',  headerName: '거리(km)', type: 'number', align: 'center', flex: 3, valueFormatter: value => formatNumber(value, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + 'km'},
  ]

  return (
    <Dialog open={props.open} PaperComponent={DraggableHeader} fullWidth maxWidth="md">
      <DialogTitle sx={{ py:1, bgcolor: '#2c61ad', color: '#fff'}} display='flex' alignItems='center'>
        <Search sx={{color: '#fff', mr: 1}}/>
        측정소 검색
        <IconButton aria-label="close" onClick={() => props.handleClose()} title="닫기" sx={{ position: 'absolute', right: 4, top: 4, color: grey[300], }}><Close /></IconButton>
      </DialogTitle>
      <DialogContent sx={{mt: 2, pb: 0}}>
        <Box display='flex' alignItems="center">
          <CompactTextField name="sttn" label="측정소명 검색"
              value={values.sttn}
              onChange={handleChange}
              onKeyDown={e => e.key === 'Enter' && handleRead()}
              size='small'
              sx={{ width: 200}}
              slotProps={{
                htmlInput: { maxLength: 10 }
              }}
              margin="dense"
               />
            <CompactTextField name="addr" label="주소 검색"
              value={values.addr}
              onChange={handleChange}
              onKeyDown={e => e.key === 'Enter' && handleRead()}
              size='small'
              sx={{ width: 200, ml: 2}}
              slotProps={{
                htmlInput: { maxLength: 10 }
              }}
              margin="dense"
               />
          <HelpTooltip>검색어 입력 후 Enter 하여 검색합니다.</HelpTooltip>
        </Box>
        <Box sx={{ height: '50vh', mb: 2}}>
          <AdminDataGrid
            rows={rows}
            columns={columns}
            getRowId={row => row.stationCode}
            density="compact"
            disableColumnMenu
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
};

export default AkSttnSearch;